.header {
  background: white !important;
  padding: 0 min(5%, 70px) !important;
  display: flex;
  .logo_img {
    margin-top: -20px;
  }
  .logo_words {
    padding-top: 5px;
    display: inline-block;
    padding-left: 10px;
    .title {
      font-size: 21px;
      font-weight: bold;
      line-height: 21px;
      margin: 10px 0 0 0;
    }
    .subtitle {
      font-size: 12px;
      line-height: 12px;
      text-align: center;
    }
  }
  .header-right-elems {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;

    .blog {
      margin: 0px 10px;
      display: inline-block;
      .a-link {
        color: black;
      }
    }
  
  }

  @media screen and (max-width: 500px) {
    .blog {
      margin-right: 0px;
    }
  }
}

.content {
  background: #f5f5f5;
  padding: 8px 5% !important;

  .main-block {
    background-color: white;
    border-radius: 5px;
    border: 3px solid white;
  }

  .error-msg {
    color: red;
  }

  .site-layout-content {
    min-height: 280px;
    padding: 24px;
  }

  .promote-wording {
    font-size: 30px;
    padding-bottom: 30px;
  }

  .trans-config {
    padding: 20px 20px 5px 20px;

    .trans-mode-select {
      margin: 10px auto;
      text-align: center;
      display: block;
    }

    .auto-calculate-explaination {
      background-color: rgb(222, 222, 222);
      text-align: center;
      padding-top: 5px;
    }
  }

  .config-form {
    margin: auto;
    width: 500px;
    text-align: center;

    .ant-col {
      text-align: center;
    }

    .suggest-size-help:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 600px) {
    .config-form {
      width: calc(100%);
    }
  }

  @media screen {
    .output-size-slider {
      display: inline-block;
      width: 200px;
      margin-right: 14px;
      vertical-align: middle;
    }
  }
  @media screen and (max-width: 600px) {
    .output-size-slider {
      display: inline-block;
      width: 100px;
      vertical-align: middle;
    }
  }

  .output-fize-input {
    width: 63px;
  }

  .submit-group {
    text-align: center;
  }

  .video-preview {
    height: 400px;
    width: 640px;
    display: block;
    margin: auto;
  }
  @media screen {
    .file-dragger {
      height: 368px !important;
      width: 600px !important;
      margin: auto;
      background: #fbfbfb !important;
      border-radius: 20px !important;
    }
  }

  @media screen and (max-width: 700px) {
    .file-dragger {
      width: 100% !important;
    }
    .video-preview {
      width: 90%;
      height: auto;
    }
  }

  .try-example-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 9px;
  }

  .sample-loading-spin {
    display: block;
    padding: 100px 0px;
  }

  .choose-file-btn {
    margin-top: 20px;
  }

  .ant-upload-text {
    margin-top: 20px !important;
    padding: 0px 20px;
  }

  .progress-bar {
    width: -webkit-calc(100% - 140px);
    width: -moz-calc(100% - 140px);
    width: calc(100% - 140px);
  }

  .download-btn {
    margin-left: 10px;
  }

  .front-info {
    padding: 24px;
    padding-bottom: 0px;
  }

  .keypoints {
    margin-top: 0px;
  }

  .summary {
    font-size: 30px;
  }

  .ant-btn-primary {
    background-color: #cf4104;
    border-color: #cf4104;
  }

  .ffmpeg-loading-hint {
    text-align: center;
  }

  .center-icon {
    font-size: 50px;
    display: block;
    margin: auto;
    margin-top: 30px;
  }
}

@media screen {
  .terms {
    margin: auto;
    width: 620px;
    text-align: center;
  }
}
@media screen and (max-width: 620px) {
  .terms {
    width: 280px;
  }
}

@primary-color: #cf4104;