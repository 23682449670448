.language-selector {
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .language-selector {
    display: none;
  }
}


@primary-color: #cf4104;