.message-block {
  margin-top: 20px;
  background: white;
  border-radius: 10px;
  padding-bottom: 20px;

  .word-block {
    .question {
      padding-top: 25px;
    }
    div {
      padding-right: 40px;
    }
  }
}

.info-block {
  padding-top: 20px;

  .display-block {
    background: white;
    border-radius: 10px;
    padding: 8px 5px;
    margin-bottom: 10px;

    .desc {
      font-size: 10px;
    }

    .format-desc {
      padding: 20px 0px;
    }

    .how-to-use-desc {
      padding: 10px 0px;
    }
  }
}

@primary-color: #cf4104;