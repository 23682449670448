.feature-block {
  .feature-icon {
    padding: 10px;
    margin-top: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .feature-title {
    font-size: 20px;
    margin: 0px ;
  }

  .feature-description {
    font-size: 11px;
  }
}
@primary-color: #cf4104;