.leaderboard-ad {
  margin: 20px auto;
}

@media screen {
  .leaderboard-ad {
    height: 90px;
    width: 970px;
  }
}

@media screen and (max-width: 1000px) {
  .leaderboard-ad {
    width: 728px;
  }
}

@primary-color: #cf4104;