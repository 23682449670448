.block-ad {
  margin: 20px auto;
}

@media screen {
  .block-ad {
    height: 200px;
    width: 300px;
  }
}

@primary-color: #cf4104;